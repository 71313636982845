/*eslint-disable*/
import React, { useEffect } from 'react'
import Header from './Header'
import BgHeader from './BgHeader'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useAccount, useSignMessage, useConnect } from 'wagmi'
import { show, clearSign, setNewSign } from '../store/modules/signStore'
import { PostExistUserApi, PostSignApi } from '../request/api'
import { message } from 'antd'
import { InjectedConnector } from 'wagmi/connectors/injected'
function Layout () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const { address, connector: activeConnector, isConnected } = useAccount()
  const { connect, connectors, error, isLoading, pendingConnector } =
    useConnect({
      connector: new InjectedConnector(),
    })

  useEffect(() => {
    connect(connectors[0])
    if (location.pathname === '/') {
      navigate('/home')
    }
  }, [])

  const clearSign_ = clearSign()

  const { data, signMessage, isError, isSuccess, signMessageAsync } =
    useSignMessage({
      message: 'Welcome to GSM !',
    })

  const PostExistUser = async () => {
    try {
      const res = await PostExistUserApi({ address: address })
      if (res.data.code === 200) {
        if (res.data.status) {
          signMessageAsync()
            .then(async (res) => {
              console.log(res)

              const setSign = setNewSign({ address: address, sign: res })
              // dispatch(setSign)
              const signRes = await PostSignApi({
                address: address,
                signed: res,
              })
              if (signRes.data.code === 200) {
                if (signRes.data.status) {
                  // createRes.data.data.address
                  localStorage.setItem('token', signRes.data.data.token)
                  localStorage.setItem('address', address)
                  // 1. 生成action对象

                  // 2. 提交action进行数据更新
                  dispatch(setSign)
                  message.success(t('signSuccess'))
                  window.location.reload()
                } else {
                  message.error(t('signError'))
                  // message.error(signRes.data.msg);
                }
              } else {
                message.error(t('signError'))
              }
            })
            .catch((error) => {
              message.error(t('signError'))
              console.log(error)
            })
        } else {
          if (location.pathname === '/home') {
            console.log(location.pathname)
          } else {
            console.log('跳转')
            navigate('/home')
          }
        }
        // } else {
        //   const createRes = await PostCreateUserApi({ address: address })
        //   if (createRes.data.code === 200) {
        //     // createRes.data.data.address

        //     signMessageAsync().then(async (res) => {
        //       console.log(res);
        //       const setSign = setNewSign({ address: address, sign: res })
        //       // dispatch(setSign)
        //       const signRes = await PostSignApi({ address: address, signed: res })
        //       if (signRes.data.code === 200) {
        //         if (signRes.data.data.status) {
        //           // createRes.data.data.address
        //           localStorage.setItem("token", res);
        //           localStorage.setItem("address", address);
        //           // 1. 生成action对象

        //           // 2. 提交action进行数据更新
        //           dispatch(setSign)
        //           message.success(t('signSuccess'))
        //           window.location.reload()
        //         } else {
        //           message.error(t('signError'))
        //           // message.error(signRes.data.msg);
        //         }

        //       } else {
        //         message.error(t('signError'))
        //       }
        //     }).catch((error) => {
        //       message.error(t('signError'))
        //       console.log(error)
        //     })

        //   } else {
        //     message.error(createRes.data.msg);
        //   }

        // }
      } else {
        message.error(res.data.msg)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (address) {
      // 签名
      if (
        localStorage.getItem('address') != address ||
        !localStorage.getItem('token')
      ) {
        // 2. 提交action进行数据更新
        dispatch(clearSign_)
        localStorage.removeItem('token') && localStorage.removeItem('address')
        PostExistUser()
      }
    }
  }, [address])
  const IsHeader = [
    '/home',
    '/nftMarket',
    '/buyNft/1',
    '/buyNft/2',
    '/buyNft/3',
    '/buyNft/4',
    '/buyNft/5',
    '/nftPortfolio',
    // '/myAssets',
    '/dao',
    '/miningMachine',
    // '/myAI',
    '/nftTokenMining',
    '/myNode',
    '/myNboBonusPlan',
  ]
  const normal_Header = [
    // '/foundation'
  ]
  return (
    <div className="min-h-screen bg-[#000] text-white">
      {IsHeader.includes(location.pathname) ? (
        <BgHeader></BgHeader>
      ) : normal_Header.includes(location.pathname) ? (
        <Header></Header>
      ) : (
        ''
      )}
      <main>
        {' '}
        <Outlet />
      </main>
      {/* <Bottom></Bottom> */}
    </div>
  )
}
export default Layout
